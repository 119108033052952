.legend {
  background-color: rgba(228, 235, 237, 0.7) !important;
  font-size: 2rem !important;
  color: rgb(36, 123, 160) !important;
  font-weight: bold !important;
  bottom: 0px !important;
  margin-left: 0 !important;
  left: auto !important;
  width: 60% !important;
}
