@import "../../breakpoints.scss";

.fullPageImage {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center top;
  height: 100vh;

  padding: 0rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  .contentContainer {
    max-width: 35rem;
    color: white;
    padding-right: 3rem;

    .buttons {
      display: flex;
      justify-content: space-between;

      .button {
        background-color: rgba(36, 123, 160);
        color: white;
        padding: 0.5rem 2rem;
        border: none;
        border-radius: 1.25rem;
        width: auto;
        font-size: 1.25rem;
        font-weight: bold;

        &:hover {
          background-color: white;
          color: rgba(36, 123, 160);
          transition: 0.15s;
          border: 1px solid rgba(36, 123, 160);
        }
      }
    }

    .header {
      font-size: 3.75rem;
    }

    .paragraph {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .fullPageImage {
    background-position: left center;
    background-attachment: scroll;

    .contentContainer {
      padding-right: 0;
      max-width: 100%;
      width: 100%;

      .buttons {
        flex-direction: column;

        .button {
          margin: 0.5rem;
        }
      }

      .header {
        font-size: 3rem;
        // text-align: center;
      }
    }
  }
}
