@import "../../breakpoints.scss";

.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  .background {
    background-color: white;
    border-radius: 0.5rem;
    min-width: 35rem;
    min-height: 20rem;
    padding-top: 0.75rem;
    position: relative;

    .bodyText {
      margin-top: 1rem;
      width: 100%;
      text-align: center;
      font-size: 1.3rem;
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 2.5rem;

      .button {
        height: 2.5rem;
        width: 7rem;
        border: 1px solid grey;
        border-radius: 0.25rem;
        cursor: pointer;
        font-size: 1rem;

        &--accept {
          background-color: #a2e2ae;
          margin-right: 1rem;
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid grey;
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header {
      width: 100%;
      text-align: center;

      .imageHolder {
        height: 5rem;

        .logo {
          // width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .modal {
    .background {
      min-width: 100%;
      border-radius: 0;
    }
  }
}
