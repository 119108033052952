@import "../../color.scss";

.banner {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $primary-blue;

  .textContainer {
    display: flex;
    flex-direction: column;
    color: #e4ebed;
    align-items: center;

    .bodyText {
      font-size: 1.5rem;
    }

    .headerText {
      font-size: 2.5rem;
      font-weight: bold;
      padding-top: 0.5rem;
    }
  }
}
