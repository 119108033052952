@import "../../breakpoints.scss";
@import "../../color.scss";

.textImageSection {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  --bg-opacity: 1;
  background-color: rgba(228, 235, 237, var(--bg-opacity));

  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;

  .backgroundDecal {
    position: absolute;
    top: 2rem;
    left: 5.5rem;
    // top: 0;
    // left: 0;
    z-index: 0;
    height: 25rem;
    object-fit: cover;
  }

  .textImageContainer {
    flex-direction: row;
    margin-top: 1.5rem;
    padding: 2.5rem 1.25rem 2.5rem 5rem;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 100%;

    .twoElementRowItem {
      width: 45%;
      display: flex;
      justify-content: center;
      margin-left: 2rem;
      overflow: hidden;

      .imageContainer {
        max-height: 37rem;
        max-width: 41rem;
        position: relative;
        min-height: 14rem;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          border-radius: 0.5rem;
          box-shadow: 1rem 1rem 0.25rem #ccc;
        }
      }

      .textContainer {
        max-width: 31.25rem;

        .header {
          font-size: 3.75rem;
          color: $primary-blue;
        }

        .paragraph {
          font-size: 1.5rem;
          line-height: 2rem;
          color: #2d3047;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .textImageSection {
    padding: 0rem 2rem;
    padding-bottom: 1rem;

    .backgroundDecal {
      width: 12.5rem;
    }

    .textImageContainer {
      flex-direction: column;
      padding: 0rem;

      .twoElementRowItem {
        width: 100%;
        padding: 0;
        box-sizing: border-box;
        margin: 0;

        .imageContainer {
          padding: 0rem;
          max-width: 100%;

          .image {
            box-shadow: none;
          }
        }

        .textContainer {
          .header {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
