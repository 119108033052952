@import "../../breakpoints.scss";

.home {
  .learnCarousel {
    margin: 0 5rem 0 5rem;
    overflow: hidden;

    .carouselButton {
      top: 45%;
      height: 4rem;
      width: 4rem;
      //   bottom: 25%;
      color: #fff;
      margin-top: 0;
      padding: 5px;
      position: absolute;
      opacity: 0.8;
      z-index: 2;
      border: 0;
      font-size: 32px;
      cursor: pointer;
      border-radius: 0 90px 90px 0;
      filter: alpha(opacity=10);
      background: #000;
      transition: all 0.5s;

      &:hover {
        transition: all 0.5s;
        width: 6rem;
      }

      &:last-of-type {
        right: 0;
        border-radius: 90px 0 0 90px;
      }
    }

    .titleCarousel {
      margin-top: 1.5rem;
      padding: 0 0 0 5rem;

      .description {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 2.5rem;
      }

      .title {
        font-size: 3.75rem;
        color: rgb(36, 123, 160);
      }

      .myCarousel {
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .home {
    .learnCarousel {
      display: none;
    }
  }
}
