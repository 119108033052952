@import "../../breakpoints.scss";
@import "../../color.scss";

.imageTextSection {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  --bg-opacity: 1;
  background-color: rgba(228, 235, 237, var(--bg-opacity));

  display: flex;
  flex-direction: row;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;

  .backgroundDecal {
    position: absolute;
    right: 10rem;
    z-index: 0;
    object-fit: cover;
    width: 25rem;
  }

  .imageTextContainer {
    flex-direction: row;
    margin-top: 1.5rem;
    padding: 2.5rem 5rem 3.75rem 1.25rem;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;

    .twoElementRowItem {
      width: 45%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &:first-of-type {
        margin-right: 2rem;
      }

      .buttons {
        width: 100%;
        max-width: 31.25rem;
        // display: flex;
        // justify-content: center;

        .button {
          background-color: $primary-blue;
          color: white;
          padding: 0.5rem 2rem;
          border: none;
          border-radius: 1.25rem;
          width: auto;
          font-size: 1.25rem;
          font-weight: bold;
          // width: 100%;

          &:hover {
            background-color: white;
            color: rgba(36, 123, 160);
            transition: 0.15s;
            border: 1px solid rgba(36, 123, 160);
          }
        }
      }

      .imageContainer {
        max-height: 37rem;
        max-width: 41rem;
        position: relative;
        margin-left: 1.25rem;
        min-height: 14rem;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
          border-radius: 0.5rem;
          box-shadow: -1rem 1rem 0.25rem #ccc;
          /* opacity: 1; */
          /* transition: opacity 500ms ease 0s; */
        }
      }

      .textContainer {
        max-width: 31.25rem;
        height: 100%;

        .header {
          font-size: 3.75rem;
          color: $primary-blue;
        }

        .paragraph {
          font-size: 1.5rem;
          line-height: 2rem;
          color: #2d3047;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .imageTextSection {
    padding: 2rem;

    .backgroundDecal {
      width: 12.5rem;
      bottom: 2rem;
      left: 50%;
    }

    .imageTextContainer {
      flex-direction: column;
      padding: 0rem;

      .twoElementRowItem {
        width: 100%;
        padding: 0;
        box-sizing: border-box;

        .buttons {
          margin-top: 1rem;

          .button {
            width: 100%;
          }
        }

        .imageContainer {
          margin-left: 0rem;
          padding-bottom: 1rem;
          max-width: 100%;

          .image {
            box-shadow: none;
          }
        }

        .textContainer {
          .header {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
