.animate {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.active {
  animation-name: fadeInUp;
  animation-duration: 0.75s;
  animation-fill-mode: both;

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
    100% {
      opacity: 1;
      transform: translateZ(0);
    }
  }
}
