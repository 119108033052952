@import "../../breakpoints.scss";

.noPaddingSection {
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  --bg-opacity: 1;
  background-color: rgba(36, 123, 160, var(--bg-opacity));
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;

  .backgroundDecal {
    position: absolute;
    right: 80rem;
    z-index: 0;
    object-fit: cover;
    width: 47rem;
    opacity: 0.3;
  }

  .backgroundDood {
    position: absolute;
    right: 80rem;
    z-index: 0;
    object-fit: cover;
    width: 47rem;
    opacity: 0.3;
  }

  .textImageContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    .twoElementRowItem {
      width: 50%;
      display: flex;
      justify-content: center;
      overflow: hidden;

      .imageContainer {
        max-height: 43.75rem;
        max-width: 41.5rem;
        position: relative;
        min-height: 14rem;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      .textContainer {
        margin-top: 3rem;
        max-width: 37.5rem;
        color: white;
        margin-left: 1rem;
        margin-right: 1rem;

        .header {
          font-size: 3.75rem;
        }

        .paragraph {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .noPaddingSection {
    .backgroundDecal {
      width: 53.5rem;
      bottom: 35.5rem;
      left: -26.5rem;
    }
    .backgroundDood {
      width: 48.5rem;
      bottom: 4.5rem;
      left: -23.5rem;
    }
    .textImageContainer {
      flex-direction: column;

      .twoElementRowItem {
        width: 100%;
        box-sizing: border-box;

        .imageContainer {
          padding: 0rem;
        }

        .textContainer {
          padding: 0rem 2rem;
          margin-left: 0rem;
          margin-right: 0rem;

          .header {
            font-size: 3rem;
          }
        }
      }
    }
  }
}
