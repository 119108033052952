@import "../../color.scss";

.learn {
  --bg-opacity: 1;
  background-color: rgba(228, 235, 237, var(--bg-opacity));

  display: flex;
  flex-direction: column;
  justify-content: center;

  .bannerContainer {
    margin-top: 6.5rem;

    .banner {
    }
  }

  .header {
    // color: rgb(9, 98, 8);

    background-color: rgba(0, 0, 0, 0.85);

    .navBar {
      .navButtons {
        .navButton {
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
        }
      }
    }
  }
}
