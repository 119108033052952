@import "../../breakpoints.scss";
@import "../../color.scss";

.header {
  .navBar {
    position: absolute;
    top: 0rem;
    right: 0rem;
    left: 0rem;
    background-color: transparent;
    display: flex;
    height: 6rem;
    justify-content: space-between;
    padding: 0rem 3.75rem;
    color: white;
    font-size: 1.45rem;
    font-weight: bold;

    .hamburgerMenuContainer {
      display: none;
    }

    .logoNav {
      height: 100%;

      .logo {
        height: 100%;
      }
    }

    .navButtons {
      display: flex;

      .navButton {
        background-color: transparent;
        border: none;
        display: block;
        margin-right: 0.75rem;
        margin-left: 0.75rem;
        cursor: pointer;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;

        &:hover {
          color: $light-green;
          transition: 0.15s;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .header {
    .navBar {
      position: fixed;
      background-color: #1d1d1d;
      padding: 0.5rem;
      z-index: 99;
      height: 4.5rem;
      width: 100vw;
      box-sizing: border-box;

      will-change: transform;

      -webkit-transition: 0.75s;
      -moz-transition: 0.75s;
      -ms-transition: 0.75s;
      -o-transition: 0.75s;
      transition: 0.75s;

      &--hidden {
        height: 0rem;
        padding: 0;
      }

      .hamburgerMenuContainer {
        display: block;
        background-color: transparent;
        border: none;
      }

      .logoNav {
        height: 100%;

        .logo {
          height: 100%;
        }
      }

      .navButtons {
        display: none;
      }
    }
  }
}
