@import "../../breakpoints.scss";

.footer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .formHolder {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .navigation {
    color: rgba(36, 123, 160);
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;

    .linksContainer {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      margin-top: 0.75rem;
      width: 20rem;

      .header {
        font-size: 1.75rem;
        font-weight: bold;
      }

      .links {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .link {
          display: flex;
          justify-content: center;
          padding: 0.25rem 0rem;
          cursor: pointer;
          text-decoration: none;
          color: rgba(36, 123, 160);
          font-size: 1.25rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    .copywright {
      color: rgba(36, 123, 160);
    }

    .logo {
      display: flex;
      justify-content: center;
      flex-flow: column wrap;
      color: rgba(36, 123, 160);
      width: 10rem;
    }
  }
}

// @media (max-width: $breakpoint-phone) {
//   .footer {
//     .infoTextContainer {
//       .linksContainer {
//         // margin: 0rem;
//       }
//     }
//   }
// }
