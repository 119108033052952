.mobileNav {
  height: 100vh;
  width: 0vw;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #1d1d1d;
  z-index: 999;
  will-change: transform;

  -webkit-transition: 0.75s;
  -moz-transition: 0.75s;
  -ms-transition: 0.75s;
  -o-transition: 0.75s;
  transition: width 0.75s;

  &--open {
    width: 80vw;
  }

  .logo {
    height: 5rem;
  }

  .navigationCancel {
    width: 100%;
    font-size: 2rem;
    line-height: 1.5rem;
    padding: 1.5rem 1.5rem;
    color: white;
    text-align: end;
  }

  .navigationList {
    padding-right: 2rem;
    text-align: end;
    list-style-type: none;

    .navigationItem {
      font-weight: bold;
      font-size: 2rem;
      padding: 0.5rem;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
}
