@import "../../breakpoints.scss";

.carouselCard {
  // position: relative;
  align-items: center;
  padding: 0 2.5rem;
  cursor: pointer;
  height: 30rem;
  min-width: 40rem;

  .carouselImg {
    // position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
    width: 100%;
  }

  .legends {
    position: absolute;
    bottom: 0;
    width: calc(100% - 80px);
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
