@import "../../breakpoints.scss";
@import "../../fonts.scss";

.contactForm {
  background-color: white;
  padding: 3rem;
  display: flex;
  width: 80%;
  box-shadow: 0 0 30px -15px black;
  margin-top: 5.5rem;
  margin-bottom: 4rem;

  .form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 50%;

    .button {
      height: 2.5rem;
      width: 100%;
      cursor: pointer;
      font-size: 1.1rem;

      background-color: rgba(36, 123, 160);
      color: white;
      padding: 0.5rem 2rem;
      border: none;
      border-radius: 1.25rem;
      font-size: 1.25rem;
      font-weight: bold;

      &:hover {
        background-color: white;
        color: rgba(36, 123, 160);
        transition: 0.15s;
        border: 1px solid rgba(36, 123, 160);
      }
    }

    .input {
      width: 100%;
      margin-bottom: 1rem;
      font-size: 1.1rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid grey;
      max-height: 3rem;
      font-family: "Playfair";
    }

    .textarea {
      width: 100%;
      margin-bottom: 1rem;
      font-size: 1.1rem;
      padding: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid grey;
      font-family: "Playfair";
    }
  }

  .textHolder {
    width: 50%;
    padding-right: 1rem;

    .bodyText {
      font-size: 1.25rem;
    }
    .header {
      font-size: 2.5rem;
    }
  }
}

@media (max-width: $breakpoint-phone) {
  .contactForm {
    box-shadow: none;
    flex-direction: column;
    margin-top: 3.5rem;
    padding: 0 2rem;
    width: 100%;

    .form {
      width: 100%;
    }

    .textHolder {
      width: 100%;
      padding: 0;
    }
  }
}
